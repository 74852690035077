
import { defineComponent, reactive, ref, onBeforeUpdate, onMounted } from 'vue';
import { useInject } from '@/utils/inject';
import { DescExposeType, SearchTableExposeType } from '@/types/types.config';
import { DataItem } from '@/types/app.global';

import CriteriaWidget from '@/components/table/CriteriaWidget.vue';
import DescWidget from '@/components/widget/DescWidget.vue';
import SearchTable from '@/components/table/SearchTable.vue';
import FormModal from '@/components/composite/FormModal.vue';
import { FormItemOptions } from '@/types/form.config';
import TableModel from '@/components/composite/TableModel.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'DistributionDetail',
  components: { TableModel, FormModal, SearchTable, DescWidget, CriteriaWidget },
  setup() {
    const { https, message, mapper, notify } = useInject();
    const route = useRoute();
    const criteriaOpts = {
      cols: [
        {
          field: 'no',
          label: '配货单号'
        }
      ]
    };

    const docNo = ref('');
    const docRef = ref<DescExposeType>();
    const docOpts = {
      loading: false,
      cols: [
        {
          field: 'no',
          label: '配货单号'
        },
        {
          field: 'orderCode',
          label: '订单号'
        },
        {
          field: 'statusDesc',
          label: '状态',
          width: 120
        },
        {
          field: 'name',
          label: '姓名',
          width: 120
        },
        {
          field: 'alias',
          label: '用户名',
          width: 120
        },
        {
          field: 'phone',
          label: '手机号',
          width: 120
        },
        {
          field: 'address',
          label: '收货地址'
        },
        {
          field: 'remark',
          label: '备注',
          width: 160
        },
        {
          field: 'createTime',
          label: '创建时间',
          width: 160
        },
        {
          field: 'updateTime',
          label: '更新时间',
          width: 160
        }
      ]
    };

    const detailRef = ref<SearchTableExposeType>();
    const detailOpts = {
      pageable: false,
      index: true,
      search: {
        remote: {
          action: https?.mall.listDistributionDetail
        }
      },
      cols: [
        {
          field: 'productName',
          label: '商品名称'
        },
        {
          field: 'outCode',
          label: '出库单号',
          ability: 'copy'
        },
        {
          field: 'logisticNo',
          label: '物流单号',
          ability: 'copy'
        },
        {
          field: 'amount',
          label: '总数'
        },
        {
          field: 'remain',
          label: '剩余数量'
        },
        {
          field: 'statusDesc',
          label: '状态'
        }
      ],
      commands: [] as any
    };

    const distCount = reactive({
      total: 0,
      remain: 0
    });

    // 配货操作
    const distOpts: any = reactive({
      title: '配货',
      visible: false,
      content: {
        entity: {} as DataItem,
        tips: {
          msg: () => `总数量${distCount.total}台，可配货数量${distCount.remain}台`
        },
        cols: [
          {
            field: 'stockType',
            label: '配货方式',
            type: 'radio',
            style: 'outline',
            defVal: '1',
            options: mapper?.device.stockType,
            required: true,
            changeNotify: true,
          },
          {
            field: 'startSn',
            label: '起始编号',
            required: true,
            visible: true
          },
          {
            field: 'endSn',
            label: '结束编号',
            required: true,
            visible: true
          },
          {
            field: 'sns',
            label: '机具编号',
            type: 'textarea',
            required: true,
            visible: false
          },
          {
            field: 'logisticNo',
            label: '物流单号',
            required: true,
          },
          {
            field: 'logisticType',
            label: '物流公司',
            type: 'select',
            mapper: ['type', 'com'],
            remote: {
              action: https?.mall.listLogisticCom
            },
            required: true,
          },
          {
            field: 'remark',
            label: '备注',
            type: 'textarea'
          }
        ],
        handleValueChange(field: string, entity: DataItem, cols: FormItemOptions[]) {
          if (field === 'stockType') {
            cols.forEach(c => {
              if (c.field === 'startSn' || c.field === 'endSn') {
                c.visible = entity[field] === '1';
              }

              if (c.field === 'sns') {
                c.visible = entity[field] === '2';
              }
            });
          }
        },
        evaluate(data: DataItem) {
          // 将回车换行替换为逗号，如果原本有逗号，再将两个逗号替换为一个逗号
          if (data.sns) {
            let sns: string = data.sns.replace(/[\r\n]/g, ',').replace(/,,/g, ',');
            if (sns.endsWith(',')) {
              sns = sns.substring(0, sns.length - 1);
            }
            data.sns = sns;
          }
          return data;
        }
      }
    });

    // 配货流水
    const flowRef = ref();
    const flowOpts = reactive({
      visible: false,
      width: '80%',
      content: {
        index: true,
        cols: [
          {
            field: 'orderCode',
            label: '订单号'
          },
          {
            field: 'outCode',
            label: '出库单号'
          },
          {
            field: 'logisticNo',
            label: '物流单号'
          },
          {
            field: 'num',
            label: '数量',
            width: 120
          },
          {
            field: 'com',
            label: '快递公司',
            width: 160
          },
          {
            field: 'statusDesc',
            label: '状态',
            width: 120
          }
        ]
      }
    });

    const onSearch = (params: DataItem) => {
      params.page = 1;
      params.size = 1;
      docOpts.loading = true;
      https?.mall.pageDistribution(params).then(res => {
        docOpts.loading = false;
        if (res.respType === 'OK') {
          if (res.respData.records && res.respData.records.length === 1) {
            const data = res.respData.records[0];
            // 查询地址
            https?.mall.getAddress({ id: data.addressId }).then(res2 => {
              if (res2.respType === 'OK' && res2.respData) {
                data.address = res2.respData.detailAddress;
              }

              docRef.value?.expose.setData(data);
              detailRef.value?.expose.loadData({ no: data.no });
            });
          } else {
            message?.warn('查询无数据');
            docRef.value?.expose.setData([]);
          }
        }
      });
    };

    const openDist = (data: DataItem) => {
      if (data.status !== '1' && data.status !== '2') {
        message?.warning('该商品已配货');
        return;
      }

      distCount.total = data.amount;
      distCount.remain = data.remain;
      distOpts.content.entity = { distributionDetailId: data.id };
      distOpts.visible = true;
    };

    // 配货操作
    const doDist = (data: DataItem) => {
      // 计算机具数量
      let num = 0;
      if (data.sns) {
        num = data.sns.split(',').length;
      } else {
        const startNum = Number.parseInt(data.startSn, 10);
        const endNum = Number.parseInt(data.endSn, 10);
        if (startNum > endNum) {
          message?.warn('起始编号不能大于结束编号');
          return;
        }
        num = endNum - startNum + 1;
      }
      data.num = num;

      https?.mall.handleDistributionDetail(data).then(res => {
        if (res.respType === 'OK') {
          distOpts.visible = false;
          notify?.success(res.respMesg);
          // 刷新明细数据
          detailRef.value?.expose.loadData({ no: docRef.value?.expose.getData().no });
        } else {
          notify?.error(res.respMesg);
        }
      });
    };

    const openDetail = (data: DataItem) => {
      https?.mall.listDistributionFlow({ distributionDetailId: data.id }).then(res => {
        if (res.respType === 'OK') {
          flowOpts.visible = true;
          flowRef.value.init(res.respData);
        }
      });
    };

    detailOpts.commands.push({ label: '配货', action: openDist, perm: 'dist' });
    detailOpts.commands.push({ label: '详情', action: openDetail });
    distOpts.action = doDist;

    const initData = () => {
      if (route.params.no) {
        docNo.value = route.params.no as string;
        onSearch({ no: docNo.value });
      }
    };

    onBeforeUpdate(initData);
    onMounted(initData);
    return { criteriaOpts, docRef, docOpts, detailRef, detailOpts, distOpts, flowRef, flowOpts, onSearch };
  }
});
