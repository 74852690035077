
import { defineComponent, PropType, ref, computed } from 'vue';
import { cloneDeep } from 'lodash';
import { useInject } from '@/utils/inject';
import { DescItemOptions, DescOptions } from '@/types/widget.config';
import { DataItem } from '@/types/app.global';
import { Converter } from '@/types/base.config';

export default defineComponent({
  name: 'DescWidget',
  props: {
    opts: {
      type: Object as PropType<DescOptions>,
      default: () => ({})
    }
  },
  setup(props) {
    const entity = ref<DataItem>({});
    const showCols = ref<DescItemOptions[][]>();
    const loading = ref(props.opts.loading || false);
    const colNum = props.opts.colNum || 3;

    let isInit = false;
    const { notify } = useInject();
    const c = new Converter();

    const showData = computed(() => {
      const item = cloneDeep(entity.value) as DataItem;
      if (isInit) {
        Object.keys(item).forEach(k => {
          if (item[k] == null) {
            item[k] = '';
          }
          const val = c.convert(k, item);
          item[k] = val == null || val === 'null' ? '' : val;
        });
      }
      return item;
    });

    const initShowCols = () => {
      const rows = [] as DescItemOptions[][];
      let row;
      props.opts.cols.forEach((x, i) => {
        if (i % colNum === 0) {
          row = [] as DescItemOptions[];
          rows.push(row);
        }
        row.push(x);
      });
      showCols.value = rows;
    };

    const initEntity = () => {
      const tmp = {} as DataItem;
      props.opts.cols.forEach(col => {
        tmp[col.field] = col.defVal || '';
      });
      entity.value = tmp;
    };

    // expose方法
    const setData = (data: DataItem) => {
      isInit = true;
      entity.value = data;
    };

    const loadData = (params: DataItem, onSuccess?: (data: DataItem) => void) => {
      loading.value = true;
      props.opts.remote?.action?.(params).then(res => {
        loading.value = false;

        if (res.respType === 'OK') {
          const respData = res.respData.records?.[0] || res.respData;
          let data = respData;
          if (Array.isArray(data) && data.length > 0) {
            data = respData[0];
          }

          setData(data);
          if (onSuccess) {
            onSuccess(getData());
          }
        } else {
          notify?.error(res.respMesg, '查询失败');
        }
      }, () => { loading.value = false; });
    };

    const getData = () => (cloneDeep(entity.value));

    initShowCols();
    initEntity();

    const expose = { setData, getData, loadData };
    return { showCols, showData, colNum, loading, expose };
  }
});
