import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-636d7514"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-bottom":"10px"} }
const _hoisted_2 = { class: "desc-item-label" }
const _hoisted_3 = { class: "desc-item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showCols, (row, rowIndex) => {
      return (_openBlock(), _createBlock(_component_a_row, { key: rowIndex }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (col, colIndex) => {
            return (_openBlock(), _createBlock(_component_a_col, {
              span: 24 / _ctx.colNum,
              key: colIndex
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_a_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 4 }, {
                        default: _withCtx(() => [
                          _createElementVNode("label", _hoisted_2, _toDisplayString(col.label) + "：", 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_a_col, { span: 20 }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.showData[col.field]), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              _: 2
            }, 1032, ["span"]))
          }), 128))
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _withDirectives(_createVNode(_component_a_spin, null, null, 512), [
      [_vShow, _ctx.loading]
    ])
  ]))
}