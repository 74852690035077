import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_criteria_widget = _resolveComponent("criteria-widget")!
  const _component_desc_widget = _resolveComponent("desc-widget")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_search_table = _resolveComponent("search-table")!
  const _component_form_modal = _resolveComponent("form-modal")!
  const _component_table_model = _resolveComponent("table-model")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_criteria_widget, {
      opts: _ctx.criteriaOpts,
      onSearch: _ctx.onSearch
    }, null, 8, ["opts", "onSearch"]),
    _createVNode(_component_a_card, { style: {"margin-bottom":"10px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_desc_widget, {
          ref: "docRef",
          opts: _ctx.docOpts
        }, null, 8, ["opts"])
      ]),
      _: 1
    }),
    _createVNode(_component_search_table, {
      ref: "detailRef",
      opts: _ctx.detailOpts
    }, null, 8, ["opts"]),
    _createVNode(_component_form_modal, { opts: _ctx.distOpts }, null, 8, ["opts"]),
    _createVNode(_component_table_model, {
      ref: "flowRef",
      opts: _ctx.flowOpts
    }, null, 8, ["opts"])
  ]))
}